'use es6';

import PortalIdParser from 'PortalIdParser';
export const getRootDashboardRoute = () => `/reports-dashboard/${PortalIdParser.get()}`;
export const getDashboardBaseUrl = () => `/reports-dashboard/${PortalIdParser.get()}`;
export const getDashboardListUrl = () => `/dashboard-list/${PortalIdParser.get()}`;
export const getRecentlyViewedDisplayCount = () => 5;
export const getReportListUrl = dashboardId => {
  const additionalParams = dashboardId ? `?dashboardId=${dashboardId}` : '';
  return `/reports-list/${PortalIdParser.get()}${additionalParams}`;
};
export const getReportLibraryUrl = (dashboardId, hasReportSetupAccess, hasCustomReportingAccess) => {
  const baseUrl = `/discover/${PortalIdParser.get()}/create/reports`;
  if (!hasReportSetupAccess) {
    return `${baseUrl}?dashboardId=${dashboardId}`;
  }
  if (hasCustomReportingAccess) {
    return `${baseUrl}/builders?dashboardId=${dashboardId}`;
  }
  return `${baseUrl}/templates?page=featured&dashboardId=${dashboardId}`;
};
export const getDataFirstFlowUrl = () => {
  return `/report-builder/${PortalIdParser.get()}/data-first`;
};
import http from 'hub-http/clients/apiClient';
import { registerQuery } from 'data-fetching-client';
export const getAllUsers = () => http.get('users/v2/app/hub-users');
const getUsersByScopeName = scopeName => http.get(`users/v2/app/scopes/${scopeName}/hub-users`);
export const FETCH_ALL_USERS = registerQuery({
  fieldName: 'hubUsers',
  args: [],
  fetcher() {
    return getAllUsers();
  }
});
export const FETCH_REPORTING_ADMINS = registerQuery({
  fieldName: 'hubReportingAdmins',
  args: [],
  fetcher() {
    return getUsersByScopeName('reporting-admin');
  }
});
export const FETCH_SUPER_ADMINS = registerQuery({
  fieldName: 'hubSuperAdmins',
  args: [],
  fetcher() {
    return getUsersByScopeName('super-admin');
  }
});
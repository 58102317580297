import styled from 'styled-components';
import ReactGridLayout from 'react-grid-layout';
export const ThemedReactGridLayout = styled(ReactGridLayout).withConfig({
  displayName: "ThemedReactGridLayout",
  componentId: "j1aykw-0"
})(["", ""], ({
  theme,
  isEditable,
  isPresentation
}) => `
  position: relative;
  transition: height 0.3s ease-in-out;
  [data-test-id="widget-resize-handle"] {
    opacity: 0;
    z-index: 1;
  }
  [data-can-transition="true"] {
    transition: transform 0.3s ease-in-out;
  }
  .react-grid-item {
    &.cssTransforms {
      transition-property: transform;
    }
    &.resizing {
      z-index: 1;
    }
    &.react-draggable-dragging {
      transition: none;
      z-index: 3;
    }
  }
  .react-grid-placeholder {
    border: ${theme.borderWidth['200']} dashed ${theme.color['border-info-default']};
    z-index: 2;
  }
  ${(isEditable || isPresentation) && `
    .react-resizable-handle {
      padding-right: ${theme.space['100']};
      padding-bottom: ${theme.space['100']};
      cursor: se-resize;
      position: absolute;
      box-sizing: border-box;
      width: 1.6rem;
      height: 1.6rem;
      bottom: ${theme.space['0']};
      right: ${theme.space['0']};
      opacity: 0;
      z-index: 2;
    }
    .react-grid-item:hover,
    .react-grid-item.resizing,
    .react-grid-item.react-draggable-dragging {
      cursor: grab;
      > .react-resizable-handle,
      > [data-test-id="widget-resize-handle"] {
        opacity: ${isEditable && !isPresentation ? 1 : 0} !important;
      }
      &:not([data-disable-border="true"]) {
        [data-widget-id] {
          border: ${theme.borderWidth['200']} solid ${theme.color['border-info-default']};
          box-shadow: ${theme.boxShadow['300']};
        }
      }
    }
  `}
  `);
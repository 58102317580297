import { ROW_ID, OBJECT_ID } from '../constants/shared';
import { BasicTypes, FieldTypes } from '../schema/column-records';
import { FieldRef } from '../schema/field-ref';
export const isFieldNameMagicId = name => name === ROW_ID || name === OBJECT_ID;
export const convertFieldToFieldRef = field => field.table ? FieldRef({
  property: field.name,
  table: field.table
}) : undefined;
const {
  DATE,
  DATETIME,
  STRING,
  NUMBER,
  BOOL,
  ENUMERATION,
  PHONE_NUMBER,
  CURRENCY_NUMBER,
  JSON,
  OBJECT_COORDINATES
} = FieldTypes;
export const FieldTypeToIconValue = {
  [STRING]: 'textDataType',
  [NUMBER]: 'numericDataType',
  [DATE]: 'dateDataType',
  [BOOL]: 'booleanDataType',
  [ENUMERATION]: 'textDataType',
  [DATETIME]: 'dateDataType',
  [PHONE_NUMBER]: 'calling',
  [CURRENCY_NUMBER]: 'currency',
  [OBJECT_COORDINATES]: 'textDataType',
  [JSON]: 'textDataType'
};
export const getBasicType = field => {
  switch (field && field.type) {
    case FieldTypes.NUMBER:
    case FieldTypes.CURRENCY_NUMBER:
      return BasicTypes.NUMBER;
    case FieldTypes.BOOL:
      return BasicTypes.BOOLEAN;
    case FieldTypes.DATE:
      return BasicTypes.DATE;
    case FieldTypes.DATETIME:
      return BasicTypes.DATETIME;
    default:
      return BasicTypes.STRING;
  }
};
import { List } from 'immutable';
import { ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS, HUBSPOT_CRM_OBJECTS_BY_ID
//@ts-expect-error Untyped dependency
} from './utils';
import { CRM_OBJECT_META_TYPE } from '../constants/crmObjectMetaTypes';
import * as http from '../request/http';
export const getMetaTypeFromObjectTypeId = objectTypeId => {
  const metaTypeString = objectTypeId.split('-')[0];
  const candidateMetaTypeNumber = Number(metaTypeString);
  if (!Object.values(CRM_OBJECT_META_TYPE).includes(candidateMetaTypeNumber) || candidateMetaTypeNumber === 0 && metaTypeString !== '0') {
    return undefined;
  }
  return candidateMetaTypeNumber;
};
export const convertDataTypeToObjectTypeId = dataType => {
  return ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.has(dataType) ? ALLOWLISTED_HUBSPOT_DEFINED_CRM_OBJECTS.get(dataType) : dataType;
};
export const convertObjectTypeIdToDataType = objectTypeId => {
  return HUBSPOT_CRM_OBJECTS_BY_ID.get(objectTypeId);
};
export const isObjectTypeId = dataOrObjectTypeId => HUBSPOT_CRM_OBJECTS_BY_ID.has(dataOrObjectTypeId);
export const getOrConvertToObjectTypeId = config => config.get('objectTypeId') || convertDataTypeToObjectTypeId(config.get('dataType'));
export const fetchCrmObjectProperties = objectOrDataTypeId => http.get(`properties/v4/groups/${objectOrDataTypeId}`, {
  query: {
    includeProperties: true,
    includeFieldLevelPermission: true
  }
}).then(response => response.get('results', List()));
/**
 * @description Extracts the ObjectTypeId, PortalId, and ObjectId from a set of Object Coordinates.
 *
 * @example
 *
 * const objectCoordinatesParts = getObjectCoordinateParts('864408372-0-102-40068508735');
 *
 * console.log(objectCoordinatesParts.ObjectTypeId); // Output: '0-102'
 * console.log(objectCoordinatesParts.PortalId); // Output: '864408372'
 * console.log(objectCoordinatesParts.ObjectId); // Output: '40068508735'
 *
 */
export const getObjectCoordinateParts = objectCoordinates => {
  const objectCoordinatesParts = objectCoordinates.split('-');
  return {
    PortalId: objectCoordinatesParts[0],
    ObjectId: objectCoordinatesParts[3],
    ObjectTypeId: `${objectCoordinatesParts[1]}-${objectCoordinatesParts[2]}`
  };
};
import { FloatingAlertStore } from 'foundations-components/transitional/components/FloatingAlertList';
const CheckoutFloatingAlertStore = FloatingAlertStore.newFloatingAlertStore();
CheckoutFloatingAlertStore.resetStore = function () {
  const alerts = this.getAlerts();
  Object.keys(alerts).forEach(alert => {
    const {
      id
    } = alerts[alert];
    this.removeAlert(id);
  });
};
export default CheckoutFloatingAlertStore;